import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import styles from './fancy-section.module.scss';

const FancySection = (props) => {
  const {
    logo,
    quote,
    quotedName,
    quotedRole,
    quotedImg,
    statValue,
    statDescription,
    img,
    flip,
    children,
    variant,
    displayCaseStudy,
    caseStudyTitle,
    caseStudyLink
  } = props;
   
  return (
    <div className={cx(styles.statHero, { [styles.flip]: flip, [styles[`variant${variant}`]]: variant })}>
      <div className={cx(styles.mainContent)}>
        <div className={cx(styles.content)}>

          { children ? <div className={cx(styles.childWrap)}>{children}</div>
            : (
              <span>
                <img src={logo} className={cx(styles.logo)} alt="" />
                <h2 className="lato"><big>&ldquo;{quote}&rdquo;</big></h2>

                <div className={cx(styles.quoted)}>
                  <img src={quotedImg} alt={quotedName} />
                  <div>
                    <p><strong>{quotedName}</strong></p>
                    <p>{quotedRole}</p>
                  </div>
                </div>
                {displayCaseStudy &&
                <div>
                  <br/>
                  <a
                    href={caseStudyLink}
                    className={cx(styles.caseStudyFeature)}>
                    {caseStudyTitle}
                  </a>
                </div>}
              </span>
            )
          }

        </div>     
      </div>

      <div className={cx(styles.accessories)}>
        { statValue && (
        <div className={cx(styles.stat)}>
          <h2>{ statValue }</h2>
          <p>{ statDescription }</p>
        </div> )}

        <div 
          className={cx(styles.featuredImage)}
          style={{
            backgroundImage: `url(${img})`,
          }}
        />
      </div>
    </div>
  )
};

FancySection.propTypes = {
  flip: PropTypes.bool,
}

FancySection.defaultProps = {
  flip: false,
}

export default FancySection;